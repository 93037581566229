<template>
  <div class="main-container">
    <quill-popup :popup-open="popupPanelOpen" @popup-close="popupPanelOpen = false" @popup-save="savePopupContents" :content="currentWiki.data.length > modifyingDataIndex ? currentWiki.data[modifyingDataIndex].description : { ops: []}" />
    <h1 class="main-heading">{{$t('WIKI')}}</h1>
    <div class="content-container">
      <div class="first-section">
        <div class="col">
          <div class="col-title">{{ $t('PROPERTIES') }}</div>
          <div class="form-container">
            <p>{{ $t(`WIKI_VALUES.NAME`) }}</p>
            <input v-model="currentWiki.name"/>
          </div>
          <div class="form-container">
            <p>{{ $t(`WIKI_VALUES.ID`) }}</p>
            <input v-model="currentWiki.id"/>
          </div>
          <div class="form-container">
            <p>{{ $t(`BOSS_VALUES.INDEX`) }}</p>
            <input v-model.number="currentWiki.index"/>
          </div>
        </div>
        <div class="col">
          <div class="col-title">{{ $t('RULES') }}</div>
          <ul>
            <li>Wiki section id's should contain only lowercase letters and underscore(_).</li>
            <li>Wiki section item id's should also follow the rules above and start with section id and underscore ([SECTIONID]_[ITEMID])</li>
            <li>If a section is related to any of items in database (e.g. units, heroes, bosses), database id must be used ([SECTIONID]_[ITEMDATABASEID])</li>
          </ul>
        </div>
      </div>
      <div class="second-section">
        <div class="col">
          <div class="col-title">{{ $t('WIKI_VALUES.WIKI_DATA') }}</div>
          <div class="row row-header">
            <div class="cell cell-header">
              {{ $t('WIKI_VALUES.ID') }}
            </div>
            <div class="cell cell-header">
              {{ $t('WIKI_VALUES.INDEX') }}
            </div>
            <div class="cell cell-header">
              {{ $t('WIKI_VALUES.NAME') }}
            </div>
            <div class="cell cell-header">
              {{ $t(`WIKI_VALUES.BANNER_ASSET`) }}
            </div>
            <div class="cell cell-header">
              {{ $t('WIKI_VALUES.REWARD_TYPE') }}
            </div>
            <div class="cell cell-header">
              {{ $t('WIKI_VALUES.REWARD_AMOUNT') }}
            </div>
            <div class="cell cell-header">
              {{ $t('WIKI_VALUES.ACTIONS') }}
            </div>
          </div>
          <div class="row" v-for="(wikiItem, index) in currentWiki.data" v-bind:key="index">
            <div class="cell cell-header">
              <input v-model="currentWiki.data[index].id"/>
            </div>
            <div class="cell cell-header">
              <input type="number" v-model="currentWiki.data[index].index"/>
            </div>
            <div class="cell cell-header">
              <input v-model="currentWiki.data[index].name"/>
            </div>
            <div class="cell cell-header">
              <select v-model="currentWiki.data[index].banner_asset">
                <option v-for="option in assetList" :value="option.doc_ref" v-bind:key="option.doc_ref">{{ option.doc_ref || "DEFAULT BANNER" }}</option>
              </select>
            </div>
            <div class="cell cell-header">
              <select v-model.number="currentWiki.data[index].rewards[0].type">
                <option v-for="rewardType in wikiConstants.REWARD_TYPES" v-bind:key="rewardType.value" :value="rewardType.value">{{ $t(rewardType.text) }}</option>
              </select>
            </div>
            <div class="cell cell-header">
              <input v-model.number="currentWiki.data[index].rewards[0].amount"/>
            </div>
            <div class="cell cell-header actions-cell">
              <button @click="openDescriptionPopup(index)" class="info"><i class="fas fa-edit"></i></button>
              <button @click="currentWiki.data.splice(index, 1)" class="error"><i class="fas fa-times"></i></button>
            </div>
          </div>
        </div>
        <div class="col">
          <button @click="addWikiItem" class="success">{{ $t('HERO_VALUES.ADD_INFO_FIELD') }}</button>
        </div>
      </div>
      <div class="buttons-section">
        <button @click="submit" class="info">{{ $t('SUBMIT') }}</button>
        <button v-if="env === 'dev'" class="info" @click="() => exportTo('staging')">Export to Staging</button>
        <button v-if="env === 'dev' || env === 'staging'" class="info" @click="() => exportTo('prod')">Export to Production</button>
      </div>
    </div>
    <ConfirmPopup :popup-open="popupOpen" :text="popupText" @popup-close="popupOpen=false" :type="popupType"/>
  </div>
</template>

<script>
import { ref, computed, reactive, onMounted, watch, onBeforeMount, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import QuillPopup from "@/components/QuillPopup";
import wikiSchema from "@/schemas/wiki";
import wikiConstants from "@/constants/wikiConstants";
import dispatchMap from "@/constants/dispatchMap";
import { io } from "socket.io-client";
import ConfirmPopup from '../../components/common/ConfirmPopup.vue'
export default {
  name: "Wiki",
  components: {
    "quill-popup": QuillPopup,
    ConfirmPopup
  },
  setup() {
    const store = useStore();
    const usedPages = ["wiki"];
    const socket = io(process.env.VUE_APP_BACKEND_URL);
    const popupOpen = ref(false);
    const popupText = ref("This page is edited by another user. Please refresh before making changes.")
    const popupType = ref("refresh")
    onBeforeMount(() => {
      usedPages.forEach(page => {
          dispatchMap[page].forEach((dispatchStr) => {
            store.dispatch(dispatchStr);
          })
      });
      }
    );
    const wiki = computed(() => store.getters['wiki/getWiki'])
    const currentWiki = reactive({ ...wikiSchema })
    const assetList = computed(() => {
      return [
        {
          doc_ref: undefined
        },
        ...store.getters['assets/getAssets'].files
      ]
    })
    const route = useRoute();
    onMounted(() => {
      if(route.query.id && wiki.value.length > 0) {
        const editingWiki = JSON.parse(JSON.stringify(wiki.value.find(x => x.id === route.query.id)))
        Object.keys(editingWiki).forEach(key => {
          currentWiki[key] = editingWiki[key]
        })
      }
      store.dispatch("loader/loadingStatus", false)

    })
    onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));

    watch(() => store.getters['wiki/getWiki'], wiki => {
      if(route.query.id && wiki.length > 0) {
        const editingWiki = JSON.parse(JSON.stringify(wiki.find(x => x.id === route.query.id)))
        Object.keys(editingWiki).forEach(key => {
          currentWiki[key] = editingWiki[key]
        })
      }
    })

    const popupPanelOpen = ref(false);
    const modifyingDataIndex = ref(0);
    const openDescriptionPopup = index => {
      modifyingDataIndex.value = index;
      popupPanelOpen.value = true;
    }
    const savePopupContents = content => {
      console.log(content)
      currentWiki.data[modifyingDataIndex.value].description = content;
      popupPanelOpen.value = false;
    }

    const addWikiItem = async () => {
      currentWiki.data.push({
        description: {
          ops: []
        },
        id: "",
        name: "",
        rewards: [
          {
            type: 0,
            amount: 10
          }
        ]
      })
    }

    const submit = () => {
      store.dispatch('wiki/updateWiki', { id: currentWiki.id, data: currentWiki }).then(() => {
        socket.emit("update", {
          socketId: socket.id,
          itemId: currentWiki.id,
          page: "Wiki"
        });
      })
    }

    socket.on('updated', (data) => {
      if (data.socketId != socket.id && data.itemId === currentWiki.id && data.page === "Wiki"){
        popupOpen.value = true;
      }
    })

    const exportTo = environment => {
      store.dispatch('wiki/exportWiki', { id: currentWiki.id, data: currentWiki, environment })
    }

    const env = process.env.ENV;

    return {
      wikiConstants,
      currentWiki,
      addWikiItem,
      submit,
      exportTo,
      env,
      popupPanelOpen,
      savePopupContents,
      openDescriptionPopup,
      modifyingDataIndex,
      assetList,
      popupOpen,
      popupText,
      popupType
    }
  }
}
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.content-container {
  width: 100%;
}
.first-section {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: start;
}

p {
  margin-bottom: 0;
  font-size: 0.875rem;
}

input, textarea, select {
  width: calc(100% - 25px);
  padding: 5px 0;
}

.col-title {
  font-weight: 600;
  font-size: 1.125rem;
}

.actions-cell {
  display: flex;
  align-items: center;
}

.col-subtitle {
  margin: 15px 0;
  font-weight: 600;
}

select {
  margin: 5px 0;
}

button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.buttons-section {
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: stretch;
}

.buttons-section button {
  font-size: 1.25rem;
}

.row {
  display: grid;
  grid-template-columns: 3fr 1fr 2fr 2fr 2fr 1fr 1fr;
  align-items: center;
}

button.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info {
  background-color: rgba(0, 0, 255, 0.15);
}

</style>