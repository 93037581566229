<template>
  <div class="popup-wrapper" :style="!popupOpen ? { display: 'none'} : {}">
    <div class="popup-container">
      <div class="popup-header">
        <div class="popup-title">
          CONTENT
        </div>
        <a class="popup-button button-close" @click="$emit('popup-close')">
          <i class="fas fa-times"></i>
        </a>
      </div>
      <div class="popup-content">
        <div id="#quill-editor" />
      </div>
      <div class="popup-footer">
          <a class="popup-button button-save" @click="emitSave">
            <i class="fas fa-check"></i>
          </a>
      </div>
    </div>
  </div>
</template>

<script>
import { watch, onMounted } from "vue";
import Quill from "quill";
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.bubble.css';
import 'quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.js';
import 'quill-emoji/dist/quill-emoji.css';

import '@/lib/quillEmoji/customBlot';

export default {
  name: "QuillPopup",
  props: ["content", "dataIndex", "popupOpen"],
  emits: ["popup-close"],
  setup(props, { emit }) {
    let quill;
    watch(() => props.content, val => {
      console.log('Setting content')
      quill.setContents(val)
    })

    const emitSave = () => {
      emit('popup-save', quill.getContents())
    }

    onMounted(() => {
      var options = {
        placeholder: 'Compose wiki description...',
        theme: 'snow',
        modules: {
          toolbar: [
            [{'color': [] }],
            ['bold',
              'italic',
              'underline',
              'strike',
            ],
            [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
            [{ 'header': [1, 2, false] }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'align': [] }],

          ],
          "emoji-toolbar": true,
          "emoji-textarea": true,
          "emoji-shortname": true
        }
      };
      quill = new Quill(document.getElementById('#quill-editor'), options)
    })
    return {
      emitSave
    }
  }
}
</script>

<style scoped>
.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999999;
  background-color: rgba(0,0,0,0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-container {
  width: 700px;
  height: 500px;
  border-radius: 25px;
  background-color: white;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ql-container {
  height: calc(100% - 42px)!important;
 }
.popup-content {
  width: 100%;
  height: 400px;
}
.popup-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.popup-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.popup-button {
  cursor: pointer;
  font-size: 1.5rem;
  padding: 5px 15px;
}

.button-close {
  color: red;

}

.button-save {
  color: green;
}

.popup-title {
  font-size: 1.3rem;
  font-weight: bold;
}
</style>