export default {
    REWARD_TYPES: [
        {
            text: "GOLD",
            value: 0
        },
        {
            text: "GEM",
            value: 1
        }
    ]
}